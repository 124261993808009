import makeRequest from '../fetch';

const magicLinkAuthenticate = async (email, jti, params) => {
    try {
        return await makeRequest(
            `/auth/identifier/magic-link/authenticate/${params}`,
            {
                method: 'post',
                body: { email, jti },
            }
        );
    } catch (error) {
        return { success: false, message: 'clientError' };
    }
};

export default magicLinkAuthenticate;
