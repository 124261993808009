import errorMessages from '../../server/errors/errorMessages';
import {
    AUTH_SIGN_IN_PATH,
    AUTH_SIGN_IN_PATH_FEDERATED,
} from '../../shared/endpoints/authV2';
import renderQueryString from '../../shared/urls/renderQueryString';
import makeRequest from '../fetch';

const signIn = async (userIdentifier, password, userContext) => {
    try {
        const { isFederated } = userContext;

        const params = renderQueryString.call(userContext);

        const signInEndpoint = isFederated
            ? AUTH_SIGN_IN_PATH_FEDERATED
            : AUTH_SIGN_IN_PATH;

        return await makeRequest(`${signInEndpoint}${params}`, {
            method: 'post',
            body: { userIdentifier, password },
        });
    } catch (error) {
        return { success: false, message: errorMessages.gracefulDegradation };
    }
};

export default signIn;
