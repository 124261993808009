import { useFormContext } from '@bbc-account/id-formaxe';
import { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME,
} from './inputs';
import { register } from '../../../services/register';
import signIn from '../../../services/signIn';
import { useStateContext } from '../../../modules/stateContext';
import {
    usePageBeforeUnloadEventContext,
    usePageBeforeUnloadEventDispatchContext,
} from '../../../pages/events';
import { PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS } from '../../../pages/events/beforeUnload/pageBeforeUnloadEventContextReducer';
import renderQueryString from '../../../../shared/urls/renderQueryString';

function prepFieldValuesForRegister(fieldValues) {
    const newFieldValues = { ...fieldValues };

    if (EMAIL_FIRST_EMAIL_FORM_INPUT_NAME !== 'email') {
        newFieldValues.email =
            newFieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

        delete newFieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];
    }

    return newFieldValues;
}

export function useEmailFirstForm({
    activeFields,
    countryCode,
    prevRoute,
    nextRoute,
    preSubmit,
    submitToRegister,
} = {}) {
    const userContext = useStateContext();

    const {
        beforeUnloadEventListenersEnabled,
    } = usePageBeforeUnloadEventContext();

    const dispatchPageBeforeUnloadEvent = usePageBeforeUnloadEventDispatchContext();

    const { fieldErrors, fieldValues, setFieldErrors } = useFormContext();

    const [formError, setFormError] = useState();

    const [sessionUrl, setSessionUrl] = useState();

    const history = useHistory();

    const { hash, pathname, search, ...locationState } = useLocation();

    const goto = useCallback(
        newPath => {
            return history.push(
                `${newPath}${renderQueryString.call({
                    ...userContext,
                    action: 'register',
                })}`,
                locationState
            );
        },
        [history, locationState, pathname]
    );

    const handleBack = useCallback(() => {
        if (typeof prevRoute !== 'undefined') {
            goto(prevRoute);
        }
    }, [goto, prevRoute]);

    const handleSubmit = useCallback(async () => {
        if (typeof preSubmit !== 'undefined') {
            const result = await preSubmit({ fieldValues });

            if (result === false) {
                return;
            }
        }

        const userContextWithRegisterAction = {
            ...userContext,
            action: 'register',
        };

        if (submitToRegister) {
            const { errors, success, message } = await register({
                paramsString: search,
                values: {
                    location: countryCode,
                    ...prepFieldValuesForRegister(fieldValues),
                },
                userContext: userContextWithRegisterAction,
            });

            if (message) {
                setFormError(message);
            }

            if (errors) {
                Object.entries(errors).forEach(([name, error]) => {
                    setFieldErrors(name, error);
                });
            }

            if (success) {
                const {
                    sessionUrl: newSessionUrl,
                    message: signinErrorMessage,
                } = await signIn(
                    fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME],
                    fieldValues[EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME],
                    userContextWithRegisterAction
                );

                if (newSessionUrl) {
                    dispatchPageBeforeUnloadEvent({
                        type:
                            PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.DISABLE_BEFORE_UNLOAD_EVENT_LISTENERS,
                    });
                    setSessionUrl(newSessionUrl);
                } else {
                    dispatchPageBeforeUnloadEvent({
                        type:
                            PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.ENABLE_BEFORE_UNLOAD_EVENT_LISTENERS,
                    });
                    setFormError(signinErrorMessage);
                }
            }
        }

        if (typeof nextRoute !== 'undefined') {
            goto(nextRoute);
        }
    }, [
        countryCode,
        dispatchPageBeforeUnloadEvent,
        fieldValues,
        goto,
        nextRoute,
        preSubmit,
        submitToRegister,
        userContext,
    ]);

    const handleSubmitInvalid = useCallback(
        ({ validationResults }) => {
            const isPartiallyValid = !!activeFields?.every(fieldName => {
                if (fieldName === EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME) {
                    return Object.values(validationResults[fieldName]).every(
                        dateValue => dateValue === true
                    );
                }

                return typeof validationResults[fieldName] === 'undefined';
            });

            if (isPartiallyValid) {
                handleSubmit();
            }
        },
        [handleSubmit]
    );

    useEffect(() => {
        if (!beforeUnloadEventListenersEnabled && sessionUrl) {
            window.location.assign(sessionUrl);
        }
    }, [beforeUnloadEventListenersEnabled, sessionUrl]);

    return {
        fieldErrors,
        fieldValues,
        formError,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    };
}
