import LinkVariant from './linkVariant';
import WhiteButtonVariant from './whiteButtonVariant';
import BlueButtonVariant from './blueButtonVariant';

const experimentVariantComponentMap = {
    white_button: WhiteButtonVariant,
    blue_button: BlueButtonVariant,
    link: LinkVariant,
};

export default experimentVariantComponentMap;
