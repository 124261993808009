import { Button, FlexContainer } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../../modules/stateContext';
import Track from '../../../shared/analytics/track';
import { resolvePageForEvent } from '../../../../../shared/utilities/events';

const BlueButtonVariant = ({ magicLinkAction, passwordAction }) => {
    const {
        signIn: { page },
    } = useStateContext();

    const resolvedPage = useMemo(() => resolvePageForEvent(page), [page]);

    return (
        <>
            <FlexContainer justifyContentStart>
                <Track container={resolvedPage} ignoreViews name="submit">
                    <Button
                        id="submit-button"
                        isFullWidth
                        isSubmit
                        formAction={passwordAction}
                    >
                        <FormattedMessage id="Continue with password" />
                    </Button>
                </Track>

                <Track container={resolvedPage} ignoreViews name="submit">
                    <Button
                        id="magic-link-submit-button"
                        isSubmit
                        variant="primary"
                        formAction={magicLinkAction}
                    >
                        Email me a link to sign in
                    </Button>
                </Track>
            </FlexContainer>
        </>
    );
};

BlueButtonVariant.displayName = 'AuthForm';

BlueButtonVariant.propTypes = {
    buttonTranslationKey: PropTypes.string,
    magicLinkAction: PropTypes.string,
    passwordAction: PropTypes.string,
};

BlueButtonVariant.defaultProps = {
    buttonTranslationKey: 'signIn.button.submit.value',
};

export default BlueButtonVariant;
